var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "modal" } },
    [
      _vm.isModalOpen("messageBox")
        ? _c("modal", { attrs: { close: _vm.closeModal } }, [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(_vm.$store.state.modal.messageBox.title)
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "body" }, slot: "body" }, [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(_vm.$store.state.modal.messageBox.text)
                }
              })
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }