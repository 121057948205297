const root = document.getElementById('app');
const env = JSON.parse(root.dataset.env);
const fileUrl = env.fileHost;

export default class Media {
    static compressed(imageId, w, h, type = 'jpg') {
        return `${fileUrl}content/${imageId}/${w}/${h}/${type}`;
    }

    static empty(w, h, text = 'No image') {
        return `//via.placeholder.com/${w}x${h}?text=${encodeURI(text)}`;
    }

    static video(code) {
        return code ? `https://www.youtube.com/embed/${code}` : null;
    }

    static file(id) {
        return id ? `${fileUrl}content/${id}` : null;
    }
}
