var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "sidebar" },
    [
      _c(
        "b-navbar",
        { staticClass: "p-0 mobile", attrs: { toggleable: "xl" } },
        [
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c("b-collapse", { attrs: { id: "nav-collapse", "is-nav": "" } }, [
            _c(
              "div",
              { staticClass: "sidebar-sticky" },
              _vm._l(_vm.menu, function(item, index) {
                return _c("menu-item", { key: index, attrs: { item: item } })
              }),
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "desktop" },
        _vm._l(_vm.menu, function(item, index) {
          return _c("HorizontalMenuItem", { key: index, attrs: { item: item } })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }